const Legal = () => {
  const content = (
    <main>
      <section>
        <div className='bg-gray-900 pt-36 pb-24 lg:pt-42 lg:pb-36 text-center'>
          <h1 className='text-white text-3xl md:text-6xl font-medium'>Legal notice</h1>
	        <p className='text-gray-300'>Jovico eBike systems</p>
        </div>
      </section>

      <section className='bg-black'>
        <div className='py-24 lg:py-36 px-4 lg:px-8 max-w-[85rem] mx-auto'>
          <div className='pt-6'>
            <h2 className='text-white text-2xl'>© Copyrigt Jovico World LLC 2023</h2>
            <div className='text-gray-300'>
              <p className='mt-6'>All rights reserved. Text, images, graphics, sound, animations and videos as well as the arrangement of the same on Jovico web sites are protected by copyright and other intellectual property rights. The content of these web sites may not be copied, disseminated, altered or made accessible to third parties for commercial purposes. Some Jovico web sites may also contain text, images, graphics, sound, animations and videos subject to third party copyrights.</p>
              <h3 className='my-4 font-semibold'>Copyright Claims and Designated Agent</h3>
              <p className='mt-4'>It is the policy of Jovico to respond to claims of intellectual property infringement. Jovico will promptly process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act, Title 17, United States Code, Section 512c2 DMCA and other applicable intellectual property laws.</p>
              <p className='mt-4'>Pursuant to the DMCA, notifications of claimed copyright infringement should be sent to a Service Provider‘s Designated Agent. Notification must be submitted to the following Designated Agent for this site:</p>
              <p className='my-4'>Director, Corporate Communications</p>
              <div>
                <ol>
                  <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
                  <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
                  <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material;</li>
                  <li>Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number and, if available, an electronic mail address at which the complaining party may be contacted;</li>
                  <li>A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law;</li>
                  <li>A statement that the information in the notification is accurate, and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                </ol>
              </div>
            </div>
          </div>

          <div className='pt-6'>
            <h2 className='my-6 text-white text-2xl'>Trademarks</h2>
              <div className='text-gray-300'>
              <p>Unless otherwise specified, all trademarks on Jovico web sites are protected under trademark law. This applies to all Jovico trademarks, service marks, typefaces, company designs, logos and emblems. The marks and design elements used on our sites are intellectual property of Jovico World LLC, USA. You agree not to copy, use or otherwise infringe upon these marks and design elements. Jovico web sites may also contain other product, service and/or company names which may be the trademarks of their respective owners.

  </p>
            </div>
          </div>

          <div className='pt-6'>
            <h2 className='my-6 text-white text-2xl'>Warranty Note</h2>
              <div className='text-gray-300'>
              <p>This information is provided by Jovico World LLC ‘‘AS IS‘‘ and without warranty of any kind, expressed or implied, including (but not limited to) any implied warranties of merchantability, fitness for any particular purpose or non-infringement. While the information provided is believed to be accurate, it may include errors or inaccuracies. Jovico World LLC will not be liable for any damages of any kind arising from use of this web site, including, but not limited to, direct, indirect, incidental, punitive and consequential damages.</p>
            </div>
          </div>

          <div className='pt-6'>
            <h2 className='my-6 text-white text-2xl'>Licence Note</h2>
              <div className='text-gray-300'>
              <p>
                The intellectual property contained in the Jovico web site, e.g. patents, trademarks and copyrights, is protected by law. This web site does not grant a license for use of intellectual property of Jovico World LLC or third parties.
              </p>
            </div>
          </div>

          <div className='pt-6'>
            <h2 className='my-6 text-white text-2xl'>Online Dispute Resolution (ODR)</h2>
            <div className='text-gray-300'>
              <p>Information regarding Online Dispute Resolution: The European Commission provides an Online Dispute Resolution (ODR) platform. This platform should act as the port of call for any out-of-court dispute settlements, concerning contractual obligations related to online purchase or service agreements. The platform is available at http://ec.europa.eu/consumers/odr/. We will not participate in any dispute settlement procedure before a consumer arbitration board.</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
  return content
}

export default Legal
