import React from 'react'
import { Map } from 'react-bootstrap-icons'

const About = () => {
  const content = (
    <main className='bg-black'>
      <section>
        <div className='min-h-screen relative flex overflow-hidden'>
          <video autoPlay muted loop className='absolute w-auto min-w-full min-h-full max-w-none z-10'>
            <source src='videos/intro-4.mp4' type='video/mp4' />
            Your browser does not support the video tag.
          </video>

          <div className='flex flex-col w-full p-6 z-30 text-white'>
            <div className='text-center px-6 pt-20 mx-auto'>
              <h1 className='mt-6 font-semibold text-3xl lg:text-4xl'>About Us</h1>
              <p className='mt-4 mb- font-medium'>We aim to optimize costs by prioritizing electricity over gas.</p>
              <p>Jovicobikes is a subsidiary of Jovicoworld, with a focus on building and maintaining innovative ebikes.</p>
            </div>
            <div className='grid grid-cols-3 gap-3 max-w-md mt-auto mx-auto'>
              <div className='text-center font-semibold'>
                <h2 className='text-xl'>10+</h2>
                <p className='mt-2 text-xs'>Employees</p>
              </div>
              <div className='text-center'>
                <div className='flex justify-center'><Map size={24} /></div>
                <p className='mt-2 text-xs font-semibold'>One Mission</p>
              </div>
              <div className='text-center font-semibold'>
                <h2 className='text-xl'>1 Tmt<sup>*</sup></h2>
                <p className='mt-2 text-xs'>CO<sub>2</sub> avoided since 2020</p>
              </div>
            </div>
            <div className='mx-auto mt-4'>
              <p className='text-xs'><sup>*</sup>1000 metric tons is equivalent to over 2 million miles of driving</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='min-h-screen flex flex-col justify-center items-center py-24 md:py-36 px-4 md:px-8 max-w-6xl mx-auto'>
            <div>
              <img src='images/j1_bg.jpeg' className='object-cover object-bottom rounded' alt='future mobility' />
            </div>
            <div className='md:flex md:space-x-4 px-1 pt-10 font-medium text-white'>
              <h1 className='md:w-1/4 text-2xl'>Mobility of the future</h1>
              <p className='md:w-3/4 mt-4 md:mt-0 text-sm text-gray-300'>Jovicobikes envisions a future where mobility is not only efficient but also environmentally responsible. E-bikes represent a clean, green, and sustainable mode of transportation that can help reduce our carbon footprint and make our cities more livable. We believe that e-bikes are at the forefront of the mobility revolution, transforming the way we commute, exercise, and experience our surroundings. Join us as we embrace the mobility of the future, where e-bikes lead the way to a cleaner, healthier planet.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='bg-[url(media/images/j1_sustain.jpeg)] bg-cover bg-bottom bg-no-repeat'>
          <div className='bg-black/75'>
            <div className='min-h-screen text-center px-6 pt-20 max-w-3xl mx-auto'>
              <h1 className='mt-6 text-white text-2xl lg:text-3xl'>The future is sustainable</h1>
              <p className='my-4 text-gray-300'>Sustainability is not just a buzzword for us; it's a way of life. Jovicobikes is committed to a greener future. Our e-bikes are designed with sustainability in mind, and we're proud to offer a range of eco-friendly e-bike accessories, from energy-efficient helmets to gloves made from recycled materials. Every choice you make with us is a step towards a more sustainable future. Together, we can create a world where transportation is efficient and eco-conscious.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='min-h-screen flex flex-col justify-center items-center py-24 md:py-36 px-4 md:px-8 max-w-6xl mx-auto'>
            <div>
              <img src='images/j1_bikes.jpg' className='object-cover rounded' alt='from startup to leader' />
            </div>
            <div className='md:flex md:space-x-4 px-1 pt-10 font-medium text-white'>
              <h1 className='md:w-1/4 text-2xl'>From a start-up to one of the leading distributors of eBikes</h1>
              <p className='md:w-3/4 mt-4 md:mt-0 text-sm text-gray-300'>What started as a small startup has grown into a leading e-bike distributor. The heart of our success is our unwavering passion for electric bicycles and our dedication to serving our customers with the utmost care. Through hard work and a commitment to quality, we've earned the trust of countless riders and partners around the world. Today, Jovicobikes stands as a testament to what ambition, innovation, and customer-centric values can achieve. Join us on this incredible journey from start-up to a leading distributor, and let's explore the world of e-bikes together.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='min-h-screen flex flex-col justify-center items-center py-24 md:py-36 px-4 md:px-8 max-w-6xl mx-auto'>
            <div className='bg-black/75'>
              <img src='images/image-57.jpg' className='object-cover object-bottom rounded' alt='future mobility' />
            </div>
            <div className='md:flex md:space-x-4 px-1 pt-10 font-medium text-white'>
              <h1 className='md:w-1/4 text-2xl'>Pioneering innovation</h1>
              <p className='md:w-3/4 mt-4 md:mt-0 text-sm text-gray-300'>At Jovicobikes, we take pride in pioneering innovations within the e-bike industry. Our journey began with a simple belief - that e-bikes could revolutionize the way we move and interact with our environment. With relentless dedication to research and development, we've introduced cutting-edge technologies, redefining what e-bikes can do. Our commitment to innovation extends to every facet of our business, from the bikes we sell to the spare parts we provide. We are not just a company; we're trailblazers in the e-bike industry.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='bg-black/75'>
            <div className='min-h-screen relative flex overflow-hidden'>
              <video autoPlay muted loop className='absolute w-auto min-w-full min-h-full max-w-none z-10'>
                <source src='videos/intro-4.mp4' type='video/mp4' />
                Your browser does not support the video tag.
              </video>

              <div className='flex flex-col w-full max-w-3xl mx-auto pt-20 p-6 z-30 text-white'>
                <div className='text-center'>
                  <h1 className='text-2xl md:text-3xl'>Meet Our Team</h1>
                  <p className='my-4 text-gray-300'>Our team is the heart and soul of Jovicobikes, and their dedication to e-bikes and sustainable mobility is what sets us apart. Together, we're making strides in pioneering innovations, promoting a sustainable future, and providing you with the very best in e-bikes and accessories.</p>
                </div>
                <div className='text-center mt-auto mb-10'>
                  <h3 className='font-semibold text-3xl'>Join Us</h3>
                  <div className='grid gap-3 sm:inline-flex sm:justify-center mt-10 w-full'>
                    <a className='py-3 px-12 inline-flex justify-center items-center gap-x-2 text-center bg-white/75 hover:bg-white/90 text-black text-sm font-medium rounded-md focus:outline-none focus:ring-none transition sm:w-auto' href='https://forms.gle/che5DTWo5N3ze1wv5'>
                      Apply Today
                    </a>
                    <a className='py-3 px-12 inline-flex justify-center items-center rounded-md font-medium bg-black/75 hover:bg-black/90 text-white focus:outline-none focus:ring-none transition-all text-sm sm:w-auto' href='/contact'>
                      Partner with Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
  return content
}

export default About
