import { Whatsapp } from "react-bootstrap-icons"

const Rentals = () => {
  const hours = [
    {
      title: 'Hourly - Affordable',
      desc: 'You can rent a bike for hours on end and enjoy your ride around the city.'
    },
    {
      title: 'Daily - Easily accessible',
      desc: 'We\'ve got a ton of loyal customers, who come back to rent eBikes on daily basis.'
    },
    {
      title: 'Weekly - Long duration',
      desc: 'There is an option to rent a bike for weeks and master all the corners of the city.'
    },
    {
      title: 'Monthly - No hidden charges',
      desc: 'We provide an opportunity to rent a bike for months and be a real city resident. Without hidden charges.'
    },
  ]

  const faqs = [
    { q: 'Is there freedom of eBiking?', a: 'Renting an e-bike is the perfect way to experience the thrill of electric cycling without your long-term commitment of ownership. You\'ll feel the wind in your hair and the power at your fingertips as you effortlessly cruise through the city. Whether it\'s for an hour, a day, a week, or more, e-bike rentals offer you the freedom to explore at your pace.' },
    { q: 'Can I test drive my dream eBike?', a: 'Are you considering purchasing an e-bike but can\'t decide which model is right for you? Renting with us allows you to try out different e-bike models, so you can make an informed decision when it\'s time to buy. Get a feel for the handling, power, and comfort of various e-bikes in real-world conditions.' },
    { q: 'Can I have eco-friendly adventure?', a: 'Renting an e-bike is not only an exciting adventure; it\'s also an environmentally conscious choice. Reduce your carbon footprint while exploring your surroundings. E-bikes are one of the most sustainable modes of transportation, and by choosing to rent one, you\'re contributing to a greener future.' },
    { q: 'How\'s your quality and safety standards like?', a: 'At Jovicobikes, we maintain our e-bike rental fleet to the highest standards. Our e-bikes are regularly inspected and serviced to ensure a safe and enjoyable riding experience. Plus, we provide you with safety gear and guidelines to ensure your well-being during your rental period.' },
    { q: 'Is exploration effortless?', a: 'E-bikes make long journeys, steep hills, and challenging terrains a breeze. With the assistance of our e-bikes, you can explore more of the city or natural landscapes without breaking a sweat. It\'s the perfect way to enjoy outdoor adventures without the exhaustion.' },
    { q: 'How convenient and cost-effective are you?', a: 'Say goodbye to traffic hassles and the costs associated with traditional transportation. E-bike rentals offer a cost-effective solution for your daily commute, leisure rides, or even vacations. With our rentals, you can skip the traffic and enjoy a hassle-free journey.' },
    { q: 'Do we have expert guidance?', a: 'Our team is here to offer advice and assistance when choosing the right e-bike for your rental needs. We\'ll help you pick the perfect e-bike that suits your preferences and requirements.' }
  ]

  const reasons = [
    {
      title: 'Low prices',
      desc: 'We provide affordable prices for renting bikes.'
    },
    {
      title: 'New eBikes',
      desc: 'We lease new eBikes for your comfort.'
    },
    {
      title: 'Good support',
      desc: 'Pick up and return eBike at our spot easily.'
    },
    {
      title: 'Convenient location',
      desc: 'Our main station is located in the center of the city.'
    },
  ]

  const steps = [
    { number: '1', title: 'Choose a bike', desc: 'Whatsapp us at +234 810 066 5479 or email us info@jovicoworld.com' },
    { number: '2', title: 'Register with us', desc: 'Make a deposit, sign our rental agreement, delegate a guarantor' },
    { number: '3', title: 'Choose a plan', desc: 'Choose a plan that works for you, be it hourly, daily, weekly or monthly' },
    { number: '4', title: 'Return in time', desc: 'Feel free to return the bike at any time.' }
  ]

  const content = (
    <main className='bg-black'>
      <section>
        <div>
          <div className='bg-[url(media/images/image-59.jpg)] bg-cover bg-center bg-no-repeat'>
            <div className='bg-black/75'>
              <div className='min-h-screen flex flex-col max-w-xl mx-auto px-4 lg:px-8 py-10'>
                <div className='text-center pt-36 pb-24 text-white'>
                  <h2 className='text-3xl font-semibold'>Rental Services</h2>
                  <p className='mt-4'>We're not just about providing e-bikes; we're about giving you an unforgettable journey filled with adventure, sustainability, and convenience. With Jovicobikes, you're not just renting an e-bike; you're renting an experience. Choose us for your e-bike rental needs, and let's embark on a memorable ride together.</p>
                </div>
                <div className='grid gap-3 sm:inline-flex sm:justify-center mt-auto w-full'>
                  <a className='py-3 px-12 inline-flex justify-center items-center gap-x-2 text-center bg-white/75 hover:bg-white/90 text-black text-sm font-medium rounded-md focus:outline-none focus:ring-none transition sm:w-auto' href='/contact'>
                    Get in touch
                  </a>
                  <div className='py-2 px-12 inline-flex justify-center items-center rounded-md font-medium text-white text-sm sm:w-auto'>
                    <span className='p-2 rounded-full bg-gray-900'><Whatsapp size={16} /></span>
                    <span className='ml-2 '>+234-123-456-7890</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='relative flex overflow-hidden'>
            <video autoPlay muted loop className='absolute w-auto min-w-full min-h-full max-w-none z-10'>
              <source src='/videos/intro-4.mp4' type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='md:flex mt-48'>
            <div className='md:w-1/2'><img src='/images/image-48.jpg' className='w-full h-[40rem] object-cover object-bottom' alt='j1 ebike' /></div>
            <div className='md:w-1/2 p-10'>
              <div className='max-w-xl'>
                <h2 className='text-3xl font-semibold text-white'>Rental Costs</h2>
                <p className='mt-4 text-gray-300'>
                  This service is currently available to customers within or around Lagos.
                </p>
              </div>

              <div className='mt-8 grid gap-8 md:mt-16 md:grid-cols-2 md:gap-12'>
                { hours.map((time, idx) => (
                  <div key={idx} className='flex items-start gap-4'>
                    <span className='shrink-0 rounded-lg bg-gray-800 p-4'>
                      <svg
                        className='h-5 w-5'
                        fill='none'
                        stroke='currentColor'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M12 14l9-5-9-5-9 5 9 5z'></path>
                        <path d='M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z'></path>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222'
                        ></path>
                      </svg>
                    </span>

                    <div>
                      <h2 className='text-lg font-bold text-gray-100'>{time.title}</h2>
                      <p className='mt-1 text-sm text-gray-300'>{time.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='grid grid-cols-2 md:grid-cols-4'>
            { steps.map((step, idx) => (
              <div key={idx} className={`${idx % 2 === 0 ? 'bg-gray-900': 'bg-gray-700'} py-20 text-center text-white`}>
                <h4 className='text-xl'>STEP</h4>
                <h3 className='my-4 text-6xl'>{step.number}</h3>
                <h5 className='uppercase text-md font-bold'>{step.title}</h5>
                <p className='p-4'>{step.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='md:flex'>
            <div className='md:w-1/2 p-10'>
              <div className='max-w-xl'>
                <h2 className='text-3xl font-bold sm:text-4xl text-white'>Why rent from us</h2>
                <p className='mt-4 text-gray-300'>
                  Though you hardly need a reason to rent our bikes, yet if you insist, here are some reasons you would want to rent from us.
                </p>
              </div>

              <div className='mt-8 grid gap-8 md:mt-16 md:grid-cols-2 md:gap-12'>
                { reasons.map((why, idx) => (
                  <div key={idx} className='flex items-start gap-4'>
                    <span className='shrink-0 rounded-lg bg-gray-800 p-4'>
                      <svg
                        className='h-5 w-5'
                        fill='none'
                        stroke='currentColor'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M12 14l9-5-9-5-9 5 9 5z'></path>
                        <path d='M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z'></path>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222'
                        ></path>
                      </svg>
                    </span>

                    <div>
                      <h2 className='text-lg font-semibold text-gray-100'>{why.title}</h2>
                      <p className='mt-1 text-sm text-gray-300'>{why.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='md:w-1/2'><img src='/images/j1_bg.jpeg' className='w-full h-[40rem] object-cover object-bottom' alt='j1 ebike' /></div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='max-w-[90rem] mx-auto md:flex mt-72 px-4 sm:px-6 lg:px-8'>
            <div className='md:w-1/2'>
              <h3 className='text-3xl font-semibold text-white'>FAQs about eBike rental</h3>
              <p className='mt-4'>Renting an e-bike from Jovicobikes isn't just about getting from point A to point B; it's an experience, and here's why you should choose to rent an e-bike with us</p>
            </div>
            <div className='md:w-1/2 ml-auto text-gray-100'>
              <div className='divide-y divide-gray-900'>
                { faqs.map((faq, idx) => (
                  <details key={idx} className='group' open>
                    <summary className='flex cursor-pointer list-none items-center justify-between py-4 text-lg font-medium'>
                      {faq.q}
                      <div>
                        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' className='block h-5 w-5 transition-all duration-300 group-open:rotate-180'>
                          <path stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' />
                        </svg>
                      </div>
                    </summary>
                    <div className='pb-4 text-gray-500'>
                      {faq.a}
                    </div>
                  </details>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
  return content
}

export default Rentals
