const Discover = () => {
  const content = (
    <main>
      <section></section>
    </main>
  )
  return content
}

export default Discover
