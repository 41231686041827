const Demo = () => {
  const content = (
    <main className='bg-black'>
      <section>
        <div>
          <div className='text-center pt-36 pb-24'>
	          <h2 className='text-3xl text-white'>Create a Service Schedule</h2>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='mx-auto max-w-[46rem] mt-20 px-4 py-16 sm:px-6 lg:px-8'>
            <div>
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd2OJFdpb3Y6rOhG_vtU8ukhNSRXDcGwvU8_BHn42pP4o2KXA/viewform?embedded=true" width="100%" height="1600" frameborder="0" marginheight="0" title="Schedule service" marginwidth="0">Loading…</iframe>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
  return content
}

export default Demo
