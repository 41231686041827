import { useState } from 'react'
import ShowSpare from '../../../components/shared/slices/ShowSpare'
import ConvertCurrency from '../../../components/shared/convertCurrency'

const Spares = () => {
  const spares = require('../../../config/spares')

  const [spare, setSpare] = useState({})
  const [spareOpen, setSpareOpen] = useState(false)

  const closeSpare = () => {
    setSpareOpen(false)
  }

  const openSpare = (pos) => {
    setSpare(pos)
    setSpareOpen(true)
  }

  const content = (
    <main className='bg-black'>
      <section>
        <div className='pt-36 pb-24 text-center'>
          <h2 className='text-3xl text-white'>Spare Parts & Accessories Inventory</h2>
        </div>
      </section>

      <section>
        <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6'>
          { spares.map((spare, idx) => (
            <div key={idx} className='bg-gray-900 p-6 md:p-10 rounded-xl'>
              <div className='flex text-white'>
                <div>
                  <h3>{spare.name}</h3>
                </div>
                <div className='ml-auto'>
                  <h3><ConvertCurrency dollar={spare?.price} /></h3>
                  <h4 className='text-sm text-right'>${spare?.price}</h4>
                </div>
              </div>
              <div className='my-3 md:my-6'>
                <img src={spare.image} className='rounded-lg' alt='spare part' />
              </div>
              <div className='flex flex-col'>
                <div className='mt-6 mb-10 text-gray-300'>
		              <div>
                    <p>{spare.desc}</p>
                    <p>{spare.cat}</p>
                  </div>
                </div>
                <div className='text-center'>
                  <button
                    onClick={() => openSpare(spare)}
                    className='rounded-md border-2 px-12 py-3 text-white hover:bg-gray-800 active:bg-gray-800'
                  >
                    View Details
                  </button>
		            </div>
              </div>
            </div>
          ))}

          <ShowSpare spare={spare} open={spareOpen} close={closeSpare} />
        </div>
      </section>
    </main>
  )
  return content
}

export default Spares
