import React from 'react'
import { ChevronUp, Instagram, Youtube } from 'react-bootstrap-icons'

const links = [
  { title: 'Legal', href: 'legal' },
  { title: 'Contact', href: 'contact' },
  { title: 'About', href: 'about' },
  { title: 'Help', href: 'help' },
]

const Footer = () => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <footer className='bg-black'>
      <section>
        <div className='relative mx-auto max-w-[90rem] px-4 py-16 lg:px-8 lg:pt-24 border-t border-gray-900'>
          <div className='absolute end-4 top-4 sm:end-6 sm:top-6 lg:end-8 lg:top-8'>
            <button className='inline-block rounded-full p-2 text-white shadow transition bg-gray-700 text-gray-300 hover:bg-gray-600 sm:p-3 lg:p-4' onClick={scrollTop}>
              <span className='sr-only'>Back to top</span>
              <ChevronUp size={20} />
            </button>
	        </div>

          <div className='lg:flex lg:items-end lg:justify-between'>
            <div>
              <span className='sr-only'>Jovico World</span>
              <div className='flex justify-center text-white lg:justify-start'>
                <img width={128} src='/images/logos/logo-white.svg' alt='Logo' />
              </div>
              <p className='mx-auto mt-6 max-w-md text-center leading-relaxed text-white/75 lg:text-left'>
                Jovico Investment Ltd founded on the 22nd of September 2020, is a company focused on the importation,{' '}
                exportation and assembling of electric bikes in Nigeria.
              </p>
            </div>
            <div className='mt-12 flex flex-wrap justify-center gap-6 md:gap-8 lg:mt-0 lg:justify-end lg:gap-12'>
              { links.map((link, idx) => (
                <div key={idx}>
                  <a className='transition text-white/75 hover:text-white' href={'/' + link.href}>
                    {link.title}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <p className='sm:flex mt-12 text-sm text-center text-white/50'>
            <span className='space-x-6 flex justify-center'>
              <a href='https://youtube.com/@jovicobikes' className='hover:text-white'><Youtube size={20} /></a>
              <a href='https://instagram.com/jovicobikes' className='hover:text-white'><Instagram size={20} /></a>
              <a href='https://x.com/jovicobikes' className='hover:bg-white bg-white/50 rounded-full p-1.5 -mt-.5'>
                <img src='/images/social/logo-x.svg' className='' width={9} alt='x logo' />
              </a>
            </span>
            <span className='block sm:flex sm:ml-auto mt-2 sm:mt-0'>Copyright &copy; 2023. All rights reserved.</span>
          </p>
        </div>
      </section>
    </footer>
  )
}

export default Footer
