const Inventory = () => {
  const content = (
    <main>
      <section>
        <div>
          <div></div>
        </div>
      </section>
    </main>
  )
  return content
}

export default Inventory
