const Shop = () => {
  const content = (
    <main className='bg-black'>
      <section>
        <div className='h-screen'><h1>Coming Soon</h1></div>
      </section>
    </main>
  )
  return content
}

export default Shop
