module.exports = [
  {
    name: '350watt Rear E-bike Motor',
    desc: '',
    cat: 'Motor',
    price: 37,
    image: '/images/motor-2.png',
    colors: [ '' ],
    misc: [ '' ],
    buyButtonId: '1PJBadKf05ghGtgcf3vchQAu'
  },
  {
    name: '350watt Motor Controller',
    desc: '',
    cat: 'Motor controller',
    price: 22,
    image: '/images/motor_controller-2.png',
    colors: [ '' ],
    misc: [ '' ],
    buyButtonId: '1PJBcvKf05ghGtgcfOpmcghM'
  },
  {
    name: 'Tubeless Tire (1.4-2.5)',
    desc: '',
    cat: 'Tubeless tire',
    price: 19,
    image: '/images/tubeless_tire.png',
    colors: [ '' ],
    misc: [ '' ],
    buyButtonId: '1PJBeaKf05ghGtgcpCW5laPo'
  },
  {
    name: 'E-bike Wire Harness',
    desc: '',
    cat: 'Wire harness',
    price: 14.5,
    image: '/images/wire_harness.png',
    colors: [ '' ],
    misc: [ '' ],
    buyButtonId: '1PJBfjKf05ghGtgc6ZYSPP0B'
  },
  {
    name: '4 in one set 48v 12Ah Lead Acid Battery',
    desc: '',
    cat: 'Lead acid battery',
    price: 60,
    image: '/images/battery-2.png',
    colors: [ '' ],
    misc: [ '' ],
    buyButtonId: '1PJBgnKf05ghGtgcL5ipQvNI'
  },
  {
    name: '48V 12Ah E-bike Charger',
    desc: '',
    cat: 'Charger',
    price: 23.5,
    image: '/images/charger.png',
    colors: [ '' ],
    misc: [ '' ],
    buyButtonId: '1PJBiMKf05ghGtgcToungnjx'
  },
  {
    name: 'E-bike Brake Handle',
    desc: '',
    cat: 'Brake handle',
    price: 15.5,
    image: '/images/brake_handle.png',
    colors: [ '' ],
    misc: [ '' ],
    buyButtonId: '1PJBjhKf05ghGtgc3mC1GNuy'
  },
  {
    name: 'E-bike Throttle',
    desc: '',
    cat: 'Throttle',
    price: 15.5,
    image: '/images/throttle-3.png',
    colors: [ '' ],
    misc: [ '' ],
    buyButtonId: '1PJBkYKf05ghGtgcpZaOtFOU'
  },
]
