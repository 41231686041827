import * as React from 'react'

const BuyButton = ({id}) => {
  // Paste the stripe-buy-button snippet in your React component
  return (
    <stripe-buy-button
      buy-button-id={'buy_btn_' + id}
      publishable-key='pk_live_51NevG8Kf05ghGtgcqwFin45w4FcP3ptwdqhqshz2JP4BDpkUHuBHQJj8EzHNq1aanA5WdwqV3W9wbYgYSKgG5FbZ00M85OUR5b'
    >
    </stripe-buy-button>
  )
}

export default BuyButton