const Repairs = () => {
  const reasons = [
    { title: 'Expertise', desc: 'Our team of skilled technicians is well-versed in the intricacies of e-bikes. Whether it\'s a minor adjustment or a major overhaul, we have the knowledge and experience to diagnose and resolve issues efficiently.' },
    { title: 'Quick Turnaround', desc: 'We understand that you depend on your e-bike for your daily commute or leisure rides. That\'s why we prioritise swift repairs, so you can get back on the road without prolonged downtime.' },
    { title: 'Genuine Parts', desc: 'We use only genuine e-bike parts in our repairs, ensuring that your e-bike\'s performance remains top-notch. Your safety is our priority, and using authentic components is a crucial part of that commitment.' },
    { title: 'Thorough Inspections', desc: 'Our repair process begins with a comprehensive inspection. We identify and address not only the obvious issues but also potential problems, so you can enjoy worry-free rides.' },
    { title: 'Transparent Pricing', desc: 'We believe in transparency when it comes to pricing. Before any repairs begin, we provide you with a detailed estimate of the costs involved. You\'ll know exactly what to expect, with no hidden fees.' },
    { title: 'Preventative Maintenance', desc: 'Regular maintenance is key to preventing major issues. We offer preventative maintenance packages to keep your e-bike in excellent condition, extending its lifespan and reducing the need for costly repairs.' },
  ]
  const repairs = [
    { number: '1', title: 'Battery Replacement', desc: 'If your e-bike\'s battery is showing signs of wear, we can replace it with a high-quality, long-lasting battery to keep your rides powered.' },
    { number: '2', title: 'Motor Servicing', desc: 'We ensure your motor operates at peak efficiency, providing the assistance you need for a smooth ride.' },
    { number: '3', title: 'Brake Adjustments', desc: 'Safety is paramount. Our team will fine-tune your brakes for optimal stopping power.' },
    { number: '4', title: 'Tire Replacements', desc: 'Worn-out tires can compromise your safety. We offer a range of tire options to suit your needs.' },
    { number: '5', title: 'Electrical System Repairs', desc: 'From malfunctioning displays to electrical issues, we can diagnose and repair electrical components.' },
    { number: '6', title: 'Frame and Component Repairs', desc: 'Whether it\'s a bent frame, a damaged fender, or a faulty gear mechanism, we\'ve got you covered.' },
  ]

  const content = (
    <main className='bg-black'>
      <section>
        <div>
          <div className='bg-[url(media/images/bike_rider.jpeg)] bg-cover bg-center bg-no-repeat'>
            <div className='bg-black/75'>
              <div className='min-h-screen flex flex-col px-4 lg:px-8'>
                <div className='max-w-xl mx-auto text-center text-white pt-36 pb-24'>
                  <h1 className='text-3xl font-semibold'>Repair Services</h1>
                  <p className='mt-4'>We understand your e-bike is more than just a mode of transportation; it's a trusted companion for your daily adventures. At Jovicobike, we understand the importance of keeping your e-bike in optimal condition. Our commitment to quality e-bike repairs ensures that your ride stays reliable and safe, mile after mile.</p>
                </div>
                <div className='grid gap-3 sm:inline-flex sm:justify-center mt-auto w-full'>
                  <a className='py-3 px-12 inline-flex justify-center items-center gap-x-2 text-center bg-white/75 hover:bg-white/90 text-black text-sm font-medium rounded-md focus:outline-none focus:ring-none transition sm:w-auto' href='/discover/schedule'>
                    Schedule a repair
                  </a>
                  <a className='py-3 px-12 inline-flex justify-center items-center rounded-md font-medium bg-gray-900/75 hover:bg-gray-900/90 text-white focus:outline-none focus:ring-none transition-all text-sm sm:w-auto' href='/inventory/spares'>
                    Get genuine parts
                  </a>
                </div>
                <div className='mt-2 mb-4 text-center text-white'>
                  <p className='mt-2 text-sm'>Your journey is important to us, and we're here to keep it smooth and enjoyable.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='py-72'>
            <div className='mt-8 grid gap-8 lg:grid-cols-2 lg:gap-16'>
              <div className='relative h-[40rem] overflow-hidden'>
                <img
                  alt='ebike'
                  src='/images/j1_bikes_sd.jpg'
                  className='absolute inset-0 h-full w-full object-cover'
                />
              </div>

              <div className='p-10 lg:p-20'>
                <div className='max-w-3xl mb-10'>
                  <h2 className='text-3xl font-semibold text-white'>
                    eBike Tune Up
                  </h2>
                </div>

                <article className='space-y-4 text-gray-300'>
                  <p>
                    Electric bikes compared to push bikes take slightly more maintenance as they encompass many mechanical moving parts, motors and electrical systems that often fail - As do all electronics over time, which need replacing.
                  </p>

                  <p>
                    Electric bikes are designed to help you go faster and farther, making cycling in general more enjoyable - Meaning these types of bikes are taken on longer rides or cruises, collecting many kilometers along the way. These mechanical components are under higher levels of stress from the power and torque of the motor, creating more wear and tear on chains, cassettes, and wheels that may require service more frequently based on the high kilometer ranges that these bikes travel.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='md:flex max-w-[90rem] mx-auto px-4 lg:px-8'>
            <div className='md:w-1/2 pb-4 md:pb-0'>
              <h3 className='text-3xl font-semibold text-white'>Why choose Jovico for eBike repairs?</h3>
            </div>
            <div className='md:w-1/2 ml-auto text-gray-100'>
              <div className='divide-y divide-gray-900'>
                {reasons.map((item, idx) => (
                  <details key={idx} className='group' open>
                    <summary className='flex cursor-pointer list-none items-center justify-between py-4 text-lg font-medium'>
                      {item.title}
                      <div className='text-gray-500'>
                        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' className='block h-5 w-5 transition-all duration-300 group-open:rotate-180'>
                          <path stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' />
                        </svg>
                      </div>
                    </summary>
                    <div className='pb-4 text-gray-500'>
                      <p className='pt-2'>{item.desc}</p>
                    </div>
                  </details>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='py-72'>
            <div className='mt-8 grid gap-8 lg:grid-cols-2 lg:gap-16 px-4 lg:px-8'>
              <div className='mt-10 lg:mt-0'>
                <h1 className='block font-semibold text-3xl text-white'>Common E-Bike Repairs We Handle:</h1>
                <p className='mt-3 text-gray-100'>Your e-bike deserves the best care, and that's precisely what we provide at Jovicobikes. We're more than a repair service; we're your partners in maintaining the integrity and performance of your e-bike. Trust us with your e-bike repairs, and let us restore your ride to its full glory.</p>
                <div className='mt-6 space-y-8'>
                  { repairs.map((item, idx) => (
                    <div key={idx} className='flex text-gray-300'>
                      <div className='w-10 flex items-center'><h3 className='text-3xl'>{item.number}</h3></div>
                      <div className='px-4 w-full'>
                        <h4 className='text-2xl'>{item.title}</h4>
                        <p className='mt-1 text-md'>{item.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className='relative h-[40rem] overflow-hidden'>
                <img
                  alt='ebike'
                  src='/images/repair.jpg'
                  className='absolute inset-0 h-full w-full object-cover'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='max-w-[90rem] mx-auto md:flex px-4 sm:px-6 lg:px-8'>
            <div className='md:w-1/2'>
              <h3 className='text-3xl font-semibold text-white'>FAQs about eBike repair</h3>
            </div>
            <div className='md:w-1/2 mt-4 md:mt-0 ml-auto text-gray-100'>
              <div className='divide-y divide-gray-900'>
                <details className='group' open>
                  <summary className='flex cursor-pointer list-none items-center justify-between py-4 text-lg font-medium'>
                    Why would I use an E-bike over a regular bike?
                    <div className='text-gray-500'>
                      <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' className='block h-5 w-5 transition-all duration-300 group-open:rotate-180'>
                        <path stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' />
                      </svg>
                    </div>
                  </summary>
                  <div className='pb-4 text-gray-500'>
                    <p className='pt-2'>E-bikes get you to where you need to go faster and easier than on a regular bike. Depending on how you choose to ride E-bikes can travel up to 32 km/h or more with the pressure of one finger! Climbing hills is a breeze... and we aren’t talking about the breeze from huffing and puffing.</p>
                    <p className='pt-2'><span className='font-medium'>Safer?</span> That might seem counter intuitive since you can go faster than on a regular bike, but you also get an easier start from stopped positions, allowing you to get through an intersection steadier and quicker. When climbing steep hills with cars nearby you can focus more of your energy on controlling the bike instead of propelling the bike.</p>
                    <p className='pt-2'><span className='font-medium'>Ditch the car?</span> Let’s not forget about the fact that biking is fun and the speed of an electric bike make it an alternative to an automobile more often than a regular bike. Studies have shown that E-bike owners ride more frequently and farther than when they relied on their traditional bike. This was the case for all ages.  The benefits of E-bikes is they are able to provide a fun transportation method for almost anyone, making it much more easy and accessible to create a bike riding experience that anyone can enjoy.</p>
                    <p className='pt-2'>Respiratory issues, hips problems and other common signs of aging certainly does not stop anyone from owning an E-bike. It allows people to still remain mobile, and go-about their day as normal. Transportation is becoming more difficult with the price of gas, cars and insurance.</p>
                    <p className='pt-2'></p>
                    <p className='pt-2'></p>
                  </div>
                </details>

                <details className='group'>
                  <summary className='flex cursor-pointer list-none items-center justify-between py-4 text-lg font-medium'>
                    How much does it cost to service my bike?
                    <div className='text-gray-500'>
                      <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' className='block h-5 w-5 transition-all duration-300 group-open:rotate-180'>
                        <path stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' />
                      </svg>
                    </div>
                  </summary>
                  <div className='pb-4 text-gray-500'>
                    <p className='pt-2'>There are 50 different technical services that we offer to fully service any bike from start to finish. Every bike is different and will require different levels of attention based on the condition overall. Typically a bike would benefit most from a Basic Tune Up covering all the necessary checkpoints to get you back on the road.</p>
                    <p className='pt-2'>A simple repair could cost as little as 2,000 naira for a headset adjustment, to upwards of thousands of naira for more extensive and labour intensive repairs.</p>
                  </div>
                </details>

                <details className='group'>
                  <summary className='flex cursor-pointer list-none items-center justify-between py-4 text-lg font-medium'>
                    Aren't electric bikes heavy?
                    <div className='text-gray-500'>
                      <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' className='block h-5 w-5 transition-all duration-300 group-open:rotate-180'>
                        <path stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' />
                      </svg>
                    </div>
                  </summary>
                  <div className='pb-4 text-gray-500'>
                    <p className='pt-2'>As one of our customers told us, 'E-bikes might be heavy to lift, but they are heavenly to ride.' Electric bikes are typically heavier than regular bikes. But the weight of any bicycle (electrical or non-electrical) is felt the most when climbing hills. The electric assist on an E-bike makes up for the additional weight many times over. Where weight does matter is if you need to lift the bike.</p>
                    <p className='pt-2'>That is one of the many reasons why E-bikes are favoured over moped style E-scooters, which often weight 150 pounds or more. If you have to climb several flights of stairs to store your bike, we strongly suggest finding a more accessible storage location.</p>
                  </div>
                </details>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
  return content
}

export default Repairs
