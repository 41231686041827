import { Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'

import ConvertCurrency from '../convertCurrency'
import Carousel from './Carousel'
import BuyButton from './BuyButton'

const ShowBike = ({ bike, close, open }) => {
  const content = (
    <Transition appear show={open} as={Fragment}>
      <Dialog as='div' className='relative z-60' onClose={close}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-90' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex mt-20 min-h-full items-center justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-6xl transform overflow-hidden rounded-2xl align-middle shadow-xl transition-all m-4 px-6 md:px-12 bg-black border border-gray-900'>
                <div className=''>
                  <div>
                    <div className='p-10 mx-auto w-full md:px-24 lg:px-8 lg:py-20'>
                      <div className='flex w-full sm:text-right'>
                        <p className='mb-6 text-base text-gray-100 md:text-lg'>
                          Starts @ <ConvertCurrency dollar={bike?.price} />
                        </p>
                        <Dialog.Title
                          as='h1'
                          className='font-bold ml-auto leading-6 text-3xl text-white'
                        >
                          <span className='uppercase'>Jovico </span>
                          {bike.name}
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className='px-6 md:px-8'>
                        {/* <span className='mx-7'>·</span> */}
                        <p className=''>Range: {bike.range?.max}{bike.range?.unit}</p>
                    </div>
                  </div>

                  <div className='w-full mx-auto'>
                    { bike.images &&
                      <Carousel autoSlide={true} >
                        {[...bike.images.map((s) => (
                          <img src={s} alt={s} />
                        ))]}
                      </Carousel>
                    }
                    { !bike.images &&
                      <div className='flex justify-center'>
                        <img src={bike.image} alt={bike.name} />
                      </div>
                    }
                  </div>

                  <div className='max-w-4xl mx-auto text-center'>
                    <Dialog.Description>
                      <h2 className='text-xl font-medium mt-10 mb-8'>About</ h2>
                      <p className='mb-2 text-gray-300'>{bike.desc}</p>
                    </Dialog.Description>

                    <div className='grid grid-cols-6 mt-10 text-gray-500'>
                      <div className='col-span-3 md:col-span-4 md:text-left'>
                        <h3 className='text-xs'>BIKE PROPERTIES</h3>
                        <div>
                          <p className='mt-3'>
                            <span className='font-medium'>Motor: </span>
                            <span className='text-gray-300'>{bike?.motor}</span>
                          </p>
                          <p className='mt-3'>
                            <span className='font-medium'>Brake: </span>
                            <span className='text-gray-300'>{bike?.brake}</span>
                          </p>
                          <p className='mt-3'>
                            <span className='font-medium'>Wheels: </span>
                            <span className='text-gray-300'>{bike?.wheels} {bike?.tyre} tire</span>
                          </p>
                          <p className='mt-3'>
                            <span className='font-medium'>Battery: </span>
                            <span className='text-gray-300'>{bike.battery}</span>
                          </p>
                          <p className='mt-3'>
                            <span className='font-medium'>Display: </span>
                            <span className='text-gray-300'>{bike.display}</span>
                          </p>
                          <p className='mt-3'>
                            <span className='font-medium'>Range: </span>
                            <span className='text-gray-300'>{bike.range?.min}km Min - {bike.range?.max}km Max</span>
                          </p>
                          <p className='mt-3'>
                            <span className='font-medium'>Weight: </span>
                            <span className='text-gray-300'>{bike.range?.min}kg Min - {bike.range?.max}kg Max</span>
                          </p>
                          <p className='mt-3'>
                            <span className='font-medium'>Speed: </span>
                            <span className='text-gray-300'>{bike.speed?.avg}km/hr Avg & {bike.speed?.max}km/hr Max</span>
                          </p>
                          <p className='mt-3'>
                            <span className='font-medium'>Frame: </span>
                            <span className='text-gray-300'>{bike?.frame}</span>
                          </p>
                        </div>
                      </div>
                      <div className='col-span-3 md:col-span-2'>
                        <h3 className='text-xs'>BIKE HIGHLIGHTS</h3>
                        <div>
                          { bike.misc?.map((highlight, key) => (
                            <p key={key} className='mt-3 text-gray-300'>{highlight}</p>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className='inline-flex justify-center mt-24 md:mt-36'>
                      {/* <a
                        href='https://wa.me/message/X3HDZKMUUHZEL1'
                        className='inline-flex justify-center rounded-md px-12 py-3 text-sm font-medium focus:outline-none text-black hover:bg-gray-100 bg-gray-300'
                      >
                        Get a Quote
                      </a> */}
                      <BuyButton id={bike.buyButtonId} />
                      <button
                        onClick={close} 
                        className='rounded-md md:ml-3 px-16 h-11 text-sm font-medium focus:outline-none text-black hover:bg-gray-100 bg-gray-300' 
                        type='button'>
                        Continue shopping
                      </button>
                    </div>
                    <div className='max-w-3xl mx-auto my-9 p-6'>
                      <p className='text-xs text-gray-500'>
                      Feel free to purchase these from our store in person or online. For Online purchases; you can transfer the money to our account and send proof of payment. If you got any specific inquiries about our ebikes and services, do reach out to us on any of the <a className='text-gray-300 hover:text-white' href='/contact'>options we've provided</a></p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
  return content
}

export default ShowBike