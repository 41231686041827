module.exports = [
  {
    name: 'J1',
    desc: 'J1 is an Electric bike equipped with pedals for cycling. It has strong first grade 48V 12Ah lead acid battery (upgradable to 48v 20Ah), with strongly built efficient Motor, delivering an after charge of about 45km. It can accommodate up to 150kg of weight.',
    price: 335,
    image: '/images/j1_yello.png',
    images: [
      '/images/j1_yello.png',
      '/images/j1_yello2.png',
      '/images/j1_yello3.png',
      '/images/j1_yello4.png',
    ],
    gear: 'Three speed mode',
    tyre: 'tubeless',
    shock: 'Front and back dual shock absorbers',
    brake: 'Front and Back brakes',
    frame: 'Iron, Aluminium and Plastic',
    motor: '48v all new copper, thickened magnetic steel 350watt motor',
    wheels: '1.4-2.5',
    battery: 'First Grade lead acid battery of 48V 12Ah.',
    display: 'LCD Display,Showing battery percentage and speed.',
    range: { min: '40', max: '45' },
    weight: { min: '150', max: '150' },
    charge: { item: '48V 12Ah battery Charger.', time: '4 - 6 hours' },
    speed: { avg: '40', max: '45' },
    colors: [ 'black', 'white', 'red', 'blue', 'gold', 'pink' ],
    misc: [ 
      'Built with special high grade lead acid batteries, has back rest at the back to improve on passenger Safety.Over-voltage protection.',
      'The controller monitors the battery voltage and shuts down the motor when the battery voltage is too high. This protects the battery from over-charge.',
      'Low-voltage protection. The controller monitors the battery voltage and shuts down the motor when the battery voltage is too low. This protects the battery from over-discharge.',
      'Over-temperature protection. The controller monitors the temperature of the FET (field-effect transistor) and shuts down the motor if they become too hot. This protects the FET power transistors.',
      'Over-current protection. Reduce the current to the motor if too much current is being supplied. This protects both the motor and the FET power transistors.',
      'Brake protection. The motor shuts down when braking even though other signals taken by the controller at the same time. For example, if the user applies brake and throttle at the same time, the brake function wins'
    ],
    buyButtonId: '1PIUxwKf05ghGtgcfgjwPFhD'
  },
  {
    name: 'NOVA SL',
    desc: 'It has strong first grade 72V 20Ah lead acid battery, with strongly built (1000W)efficient Motor, delivering an after charge of about 70-80km.',
    price: 970,
    image: '/images/nova_sl.png',
    images: [
      '/images/nova_sl.png',
      '/images/nova_sl2.png',
      '/images/nova_sl3.png',
      '/images/nova_sl4.png',
    ],
    gear: '',
    tyre: 'tubeless',
    shock: '',
    brake: 'Disc',
    frame: '',
    motor: '1000w motor',
    wheels: '90/90-12',
    battery: '72v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '70', max: '80' },
    weight: { min: '125' , max: '150' },
    charge: { item: '', time: '' },
    speed: { avg: '', max: '70' },
    colors: [ '' ],
    misc: [
      'Equiped with 6 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'Comes with FREE Tailbox for storage',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIVseKf05ghGtgccwgXH6xu'
  },
  {
    name: 'ROCKY ST',
    desc: 'It has strong first grade 72V 20Ah lead acid battery, with strongly built (1000W)efficient Motor, delivering an after charge of about 70-80km.',
    price: 855,
    image: '/images/rocky_st.png',
    images: [
      '/images/rocky_st.png',
      '/images/rocky_st2.png',
      '/images/rocky_st3.png',
      '/images/rocky_st4.png',
    ],
    gear: '',
    tyre: 'tubeless',
    shock: '',
    brake: 'Disc',
    frame: '',
    motor: '1000w motor',
    wheels: '130/90-10',
    battery: '72v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '70', max: '80' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '70', max: '70' },
    colors: [ '' ],
    misc: [
      'Equiped with 6 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIVwYKf05ghGtgclrgFkbv5'
  },
  {
    name: 'SURGE DX',
    desc: 'It has strong first grade 72V 32Ah lead acid battery, with strongly built (2000W)efficient Motor, delivering an after charge of about 90-100km.',
    price: 1665,
    image: '/images/surge_dx.png',
    gear: '',
    tyre: 'vacuum',
    shock: '',
    brake: 'Disc',
    frame: '',
    motor: '2000w motor',
    wheels: 'Front:110/70-17, Rear:140/70-16',
    battery: '72v 32Ah lead acid battery',
    display: 'LCD',
    range: { min: '90', max: '100' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '75', max: '75' },
    colors: [ '' ],
    misc: [
      'Equiped with 6 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'High quality follow come Charger',
      'Anti-theft system and remote control',
      'Equipped with electric exhaust and sound system'
    ],
    buyButtonId: '1PIVxVKf05ghGtgccpapvN2e'
  },
  {
    name: 'SURGE YZ',
    desc: 'It has strong first grade 72V 20Ah lead acid battery, with strongly built (2000W)efficient Motor, delivering an after charge of about 70-80km.',
    price: 1265,
    image: '/images/surge_yz.png',
    gear: '',
    tyre: 'vacuum',
    shock: '',
    brake: 'Disc',
    frame: '',
    motor: '2000w motor',
    wheels: 'Front:120/70-12, Rear:130/70-12',
    battery: '72v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '70', max: '80' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '75', max: '75' },
    colors: [ '' ],
    misc: [
      'Equiped with 6 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIVz9Kf05ghGtgc196NAdCa'
  },
  {
    name: 'SURGE XY',
    desc: 'It has strong first grade 72V 20Ah lead acid battery, with strongly built (2000W)efficient Motor, delivering an after charge of about 70-80km.',
    price: 1315,
    image: '/images/surge_xy.png',
    gear: '',
    tyre: 'vacuum',
    shock: '',
    brake: 'Disc',
    frame: '',
    motor: '2000w motor',
    wheels: 'Front:110/70-17, Rear:140/70-16',
    battery: '72v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '70', max: '80' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '', max: '75' },
    colors: [ '' ],
    misc: [
      'Equiped with 6 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIVzzKf05ghGtgcBeMbki4i'
  },
  {
    name: 'ZEN D',
    desc: 'It has strong first grade 60V 20Ah lead acid battery, with strongly built (1000W)efficient Motor, delivering an after charge of about 60-70km.',
    price: 755,
    image: '/images/zen_d.png',
    images: [
      '/images/zen_d.png',
      '/images/zen_d2.png',
      '/images/zen_d3.png',
    ],
    gear: '',
    tyre: 'vacuum',
    shock: 'Front and Rear oil pressure damping',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '1000w motor',
    wheels:'3.5-10',
    battery: '60v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '60', max: '70' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '60', max: '60' },
    colors: [ '' ],
    misc: [
      'Equiped with 5 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'Comes with FREE Tailbox for storage',
      'High quality follow come Charger',
      'Premium design structure',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIW0fKf05ghGtgctns0dwhb'
  },
  {
    name: 'NATIVE NG',
    desc: 'It has strong first grade 72V 20Ah lead acid battery, with strongly built (1500W)efficient Motor, delivering an after charge of about 80km.',
    price: 965,
    image: '/images/native_ng.png',
    gear: '',
    tyre: 'vacuum',
    shock: 'Front and Rear oil pressure damping',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '1500w motor',
    wheels: 'Front:2.5-17, Rear:2.75-16',
    battery: '72v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '60', max: '80' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '70', max: '70' },
    colors: [ '' ],
    misc: [
      'Equiped with 6 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'Comes with FREE Tailbox for storage',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIW1MKf05ghGtgcPVyy62p9'
  },
  {
    name: 'SPECTRE X',
    desc: 'It has strong first grade 72V 20Ah lead acid battery, with strongly built (1500W)efficient Motor, delivering an after charge of about 60-70km.',
    price: 1335,
    image: '/images/spectre_x.png',
    gear: '',
    tyre: 'vacuum',
    shock: 'Front and Rear oil pressure damping',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '1500w motor',
    wheels: 'Front:4.0-17, Rear:4.0-17',
    battery: '72v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '60', max: '70' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '75', max: '75' },
    colors: [ '' ],
    misc: [
      'Equiped with 6 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'Comes with FREE Tailbox for storage',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIW26Kf05ghGtgcl7VDD5nu'
  },
  {
    name: 'TENT EC',
    desc: 'It has strong first grade 60V 20Ah lead acid battery, with strongly built (1000W)efficient Motor, delivering an after charge of about 60-70km.',
    price: 710,
    image: '/images/tent_ec.png',
    gear: '',
    tyre: 'vacuum',
    shock: 'Front and Rear oil pressure damping',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '1000w motor',
    wheels: 'Front:3.0-10, Rear:3.0-10',
    battery: '60v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '60', max: '70' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '60', max: '60' },
    colors: [ '' ],
    misc: [
      'Equiped with 5 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'Comes with FREE Tailbox for storage',
      'High quality follow come Charger',
      'Anti-theft system and remote control',
      'Comes with all round steel protector'
    ],
    buyButtonId: '1PIW2xKf05ghGtgcuopwGyTM'
  },
  {
    name: 'TENT EJ',
    desc: 'It has strong first grade 60V 20Ah lead acid battery, with strongly built (1000W)efficient Motor, delivering an after charge of about 60-70km.',
    price: 715,
    image: '/images/tent_ej.png',
    images: [
      '/images/tent_ej.png',
      '/images/tent_ej2.png',
      '/images/tent_ej3.png',
    ],
    gear: '',
    tyre: 'vacuum',
    shock: 'Front and Rear oil pressure damping',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '1000w motor',
    wheels: 'Front:3.0-10, Rear:3.0-10',
    battery: '60v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '60', max: '70' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '60', max: '60' },
    colors: [ '' ],
    misc: [
      'Equiped with 5 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'Comes with FREE Tailbox for storage',
      'High quality follow come Charger',
      'Anti-theft system and remote control',
      'Comes with all round steel protector'
    ],
    buyButtonId: '1PIW3ZKf05ghGtgcV5aJoUv7'
  },
  {
    name: 'TENT ER',
    desc: 'It has strong first grade 60V 20Ah lead acid battery, with strongly built (1000W)efficient Motor, delivering an after charge of about 60-70km.',
    price: 710,
    image: '/images/tent_er.png',
    images: [
      '/images/tent_er.png',
      '/images/tent_er2.png',
      '/images/tent_er3.png',
      '/images/tent_er4.png',
    ],
    gear: '',
    tyre: 'vacuum',
    shock: 'Front and Rear oil pressure damping',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '1000w motor',
    wheels: 'Front:3.0-10, Rear:3.0-10',
    battery: '60v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '50', max: '60' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '50', max: '60' },
    colors: [ '' ],
    misc: [
      'Equiped with 5 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'Comes with FREE Tailbox for storage',
      'High quality follow come Charger',
      'Anti-theft system and remote control',
    ],
    buyButtonId: '1PIW5AKf05ghGtgcciCD6zfJ'
  },
  {
    name: 'HORNET BX',
    desc: 'It has strong first grade 60V 20Ah lead acid battery, with strongly built (1000W)efficient Motor, delivering an after charge of about 60-70km.',
    price: 705,
    image: '/images/hornet_bx.png',
    images: [
      '/images/hornet_bx.png',
      '/images/hornet_bx2.png',
      '/images/hornet_bx3.png',
    ],
    gear: '',
    tyre: 'tubeless',
    shock: 'Front and Rear oil pressure damping',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '1000w motor',
    wheels: 'Front:3.0-10, Rear:3.0-10',
    battery: '60v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '60', max: '70' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '60', max: '60' },
    colors: [ '' ],
    misc: [
      'Equiped with 5 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'Comes with FREE Tailbox for storage',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIW6mKf05ghGtgcU2QjjG03'
  },
  {
    name: 'HORNET C',
    desc: 'It has strong first grade 60V 20Ah lead acid battery, with strongly built (1000W)efficient Motor, delivering an after charge of about 60-70km.',
    price: 745,
    image: '/images/hornet_c.png',
    images: [
      '/images/hornet_c.png',
      '/images/hornet_c2.png',
    ],
    gear: '',
    tyre: 'vacuum',
    shock: 'Front and Rear oil pressure damping',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '1000w motor',
    wheels: 'Front:3.0-10, Rear:3.0-10',
    battery: '60v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '60', max: '70' },
    weight: { min: '...' , max: '...' },
    charge: { item: '', time: '' },
    speed: { avg: '60', max: '60' },
    colors: [ '' ],
    misc: [
      'Equiped with 5 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'USB port for charging phones',
      'Reverse mode function',
      'Comes with FREE Tailbox for storage',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIW7wKf05ghGtgcKtg6vU7H'
  },
  {
    name: 'CITY R1',
    desc: 'It has strong first grade 48V 12Ah lead acid battery (upgradable to 48v 20Ah), with strongly built (350W)efficient Motor, delivering an after charge of about 45km.',
    price: 425,
    image: '/images/city_r1.png',
    gear: '',
    tyre: 'vacuum',
    shock: '',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '350w motor',
    wheels:'14x2.5',
    battery: '48v 12Ah lead acid battery',
    display: 'LCD',
    range: { min: '40', max: '45' },
    weight: { min: '150' , max: '150' },
    charge: { item: '', time: '' },
    speed: { avg: '40', max: '40' },
    colors: [ '' ],
    misc: [
      'Equiped with 4 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'High quality follow come Charger',
      'Anti-theft system, smartcard and remote control'
    ],
    buyButtonId: '1PIW8bKf05ghGtgcKTLPu6E6'
  },
  {
    name: 'CITY R2',
    desc: 'It has strong first grade 48V 12Ah lead acid battery (upgradable to 48v 20Ah), with strongly built (350W)efficient Motor, delivering an after charge of about 45km.',
    price: 455,
    image: '/images/city_r2.png',
    gear:'',
    tyre:'vacuum',
    shock:'',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '350w motor',
    wheels:'14x2.5',
    battery: '48v 12Ah lead acid battery',
    display: 'LCD',
    range: { min: '40', max: '45' },
    weight: { min: '150' , max: '150' },
    charge: { item: '', time: '' },
    speed: { avg: '40', max: '40' },
    colors: [ '' ],
    misc: [
      'Equiped with 4 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIW9IKf05ghGtgcdoN3tQEr'
  },
  {
    name: 'CITY R3',
    desc: 'It has strong first grade 48V 12Ah lead acid battery (upgradable to 48v 20Ah), with strongly built (350W)efficient Motor, delivering an after charge of about 45km.',
    price: 440,
    image: '/images/city_r3.png',
    images: [
      '/images/city_r3.png',
      '/images/city_r3b.png',
      '/images/city_r3c.png',
      '/images/city_r3d.png',
    ],
    gear:'',
    tyre:'vacuum',
    shock:'',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '350w motor',
    wheels:'14x2.5',
    battery: '48v 12Ah lead acid battery',
    display: 'LCD',
    range: { min: '40', max: '45' },
    weight: { min: '150' , max: '150' },
    charge: { item: '', time: '' },
    speed: { avg: '40', max: '40' },
    colors: [ '' ],
    misc: [
      'Equiped with 4 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIWA6Kf05ghGtgcWi5IZGsu'
  },
  {
    name: 'CITY R4',
    desc: 'It has strong first grade 48V 12Ah lead acid battery (upgradable to 48v 20Ah), with strongly built (350W)efficient Motor, delivering an after charge of about 45km.',
    price: 430,
    image: '/images/city_r4.png',
    gear: '',
    tyre: 'vacuum',
    shock: '',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '350w motor',
    wheels: '14x2.5',
    battery: '48v 12Ah lead acid battery',
    display: 'LCD',
    range: { min: '40', max: '45' },
    weight: { min: '150' , max: '150' },
    charge: { item: '', time: '' },
    speed: { avg: '40', max: '40' },
    colors: [ '' ],
    misc: [
      'Equiped with 4 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIWAhKf05ghGtgciAUMGJjj'
  },
  {
    name: 'CITY R5',
    desc: 'It has strong first grade 48V 20Ah lead acid battery, with strongly built (500W)efficient Motor, delivering an after charge of about 50km.',
    price: 495,
    image: '/images/city_r5.png',
    images: [
      '/images/city_r5.png',
      '/images/city_r5b.png',
      '/images/city_r5c.png',
      '/images/city_r5d.png',
    ],
    gear: '',
    tyre: 'tube',
    shock: '',
    brake: 'Disc',
    frame: 'Iron Aluminium and Plastic',
    motor: '500w motor',
    wheels: '20x2.125 inches',
    battery: '48v 20Ah lead acid battery',
    display: 'LCD',
    range: { min: '40', max: '45' },
    weight: { min: '150' , max: '150' },
    charge: { item: '', time: '' },
    speed: { avg: '45', max: '45' },
    colors: [ '' ],
    misc: [
      'Equiped with 4 batteries (12v 20AH battery each)',
      'Comes with FREE high quality helmet',
      'High quality follow come Charger',
      'Anti-theft system and remote control'
    ],
    buyButtonId: '1PIWBOKf05ghGtgcGtmeQiz7'
  }
]
