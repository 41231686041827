import { useState } from 'react'
import ShowBike from '../../../components/shared/slices/ShowBike'
import ConvertCurrency from '../../../components/shared/convertCurrency'

const Bikes = () => {
  const bikes = require('../../../config/bikes')

  const [bike, setBike] = useState({})
  const [bikeOpen, setBikeOpen] = useState(false)

  const closeBike = () => {
    setBikeOpen(false)
  }

  const openBike = (bike) => {
    setBike(bike)
    setBikeOpen(true)
  }

  const content = (
    <main className='bg-black'>
      <section>
        <div className='pt-36 pb-24 text-center'>
          <h2 className='text-3xl text-white'>eBikes Inventory</h2>
        </div>
      </section>

      <section>
        <div>
          <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6 pb-24'>
            { bikes.map((bike, idx) => (
              <div key={idx} className='bg-gray-900 p-6 md:p-10 rounded-xl'>
                <div className='flex text-white'>
                  <div>
                    <h3>{bike.name}</h3>
                  </div>
                  <div className='ml-auto'>
                    <h3><ConvertCurrency dollar={bike?.price} /></h3>
                    <h4 className='text-sm text-right'>${bike?.price}</h4>
                  </div>
                </div>
                <div className='my-3 md:my-6'>
                  <img src={bike.image} className='w-full rounded-lg' alt='bike pic' />
                </div>
                <div className='flex flex-col'>
                  <div className='mt-6 mb-10 text-gray-300'>
                    <div className='grid grid-cols-3 divide-x divide-gray-800'>
                      <div className='text-center'>
                        <h4>
                          {bike.range?.max}<span>km</span>
                        </h4>
                        <p className='text-sm'>Range (EPA est.)</p>
                      </div>
                      <div className='text-center'>
                        <h4>
                          {bike.speed?.max}<span>km/hr</span>
                        </h4>
                        <p className='text-sm'>Max Speed</p>
                      </div>
                      <div className='text-center'>
                        <h4>
                          {bike.weight?.max}<span>kg</span>
                        </h4>
                        <p className='text-sm'>Weight</p>
                      </div>
                    </div>
                    <div className='mt-4'>
                      <p className='mt-2'>{bike.desc}</p>
                      {/* <p className='mt-2'>Display: {bike.display}</p>
                      <p className='mt-2'>Wheels: {bike.wheels}</p> */}
                    </div>
                  </div>
                  <div className='mt-auto text-center'>
                    <button
                      onClick={() => openBike(bike)}
                      className='rounded-md border-2 px-12 py-3 text-white hover:bg-gray-800 active:bg-gray-800'
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <ShowBike bike={bike} open={bikeOpen} close={closeBike} />
          </div>
        </div>
      </section>
    </main>
  )
  return content
}

export default Bikes
