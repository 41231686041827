import React, { useState, useEffect } from 'react'
import axios from 'axios'

const MIN_RATE = 1250.0

function ConvertCurrency({ dollar }) {
  const fromCurrency = 'USD'
  const toCurrency = 'NGN'
  const [amount, setAmount] = useState(0)
  const [rate, setRate] = useState(0.0)

  // Fetch exchange rates on component mount
  useEffect(() => {
    const fetchRates = async () => {
      const options = {
        method: 'GET',
        url: 'https://api.currencybeacon.com/v1/convert',
        params: {
          api_key: '7ZkvvzyGPqIWKjRTjBfURZw9ugiUWsLT',
          from: fromCurrency,
          to: toCurrency,
          amount: 1
        }
      }

      try {
        const res = await axios.request(options)
        const fRate = parseFloat(res.data.value)
        const iRate = (fRate < MIN_RATE) ? MIN_RATE : fRate
        if (res.data) setRate(iRate)

        setAmount(parseInt(dollar))
      } catch(err) {
        console.log(err)
      }
    }
    fetchRates()
  }, [fromCurrency, toCurrency, dollar])

  // Calculate converted amount
  const convertAmount = (amount || 0) * (rate || 0)
  const testRate = (rate && !isNaN(amount) && rate > 0) ? true : false

  return (
    <span>₦{ testRate ? convertAmount.toFixed(2) : '...'}</span>
  )
}

export default ConvertCurrency