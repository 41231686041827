import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import Layout from '../components/Layout'
import Home from '../pages/Home/Home'
import About from '../pages/About/About'
import Contact from '../pages/Contact/Contact'
import Legal from '../pages/Legal/Legal'
import Help from '../pages/Help/Help'

import Inventory from '../pages/Products/Inventory'
import Bikes from '../pages/Products/Bikes/Bikes'
import Spares from '../pages/Products/Spares/Spares'

import Services from '../pages/Services/Services'
import Rentals from '../pages/Services/Rentals/Rentals'
import Repairs from '../pages/Services/Repairs/Repairs'

import Discover from '../pages/Discover/Discover'
import Schedule from '../pages/Discover/Schedule/Schedule'
import Shop from '../pages/Shop/Shop'

const App = () => {
  const location = useLocation()

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change

  return (
    <Routes>
      {/* Public routes */}
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        
        <Route path='about' element={<About />} />
        <Route path='contact' element={<Contact />} />
        <Route path='legal' element={<Legal />} />
        <Route path='help' element={<Help />} />

        <Route path='inventory/'>
          <Route index element={<Inventory />} />
          <Route path='bikes' element={<Bikes />} />
          <Route path='spares' element={<Spares />} />
        </Route>

        <Route path='services/'>
          <Route index element={<Services />} />
          <Route path='rentals' element={<Rentals />} />
          <Route path='repairs' element={<Repairs />} />
        </Route>

        <Route path='discover/'>
          <Route index element={<Discover />} />
          <Route path='schedule' element={<Schedule />} />
        </Route>

        <Route path='shop/'>
          <Route index element={<Shop />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
