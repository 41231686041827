const Contact = () => {
  const content = (
    <main className='bg-black'>
      <section>
        <div className='mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8'>
          <div className='relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0'>
            <div className='mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left'>
              <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
                Contact Us
              </h2>
              <p className='mt-6 text-lg leading-8 text-gray-300'>
                Any questions or comments about Jovico eBike systems? You can use the form below to contact us.
              </p>
            </div>
            <div className='relative mt-16 h-80 lg:mt-8'>
              <img
                className='absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10'
                src='images/contact.jpeg'
                alt='App screenshot'
                width={1824}
                height={1080}
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='mx-auto max-w-[46rem] mt-20 px-4 py-16 sm:px-6 lg:px-8'>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd2TthSQx93d2PzvgePB9R90ffzj63KlnC5D3kX0oWzCr8Otg/viewform?embedded=true" title="Contact us" width="100%" height="1500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='grid sm:grid-cols-2'>
            <div><img src='images/help.jpeg' className='' alt='got a question?' /></div>
            <div className='p-4 lg:p-8 text-white'>
              <h2 className='text-2xl'>FAQs</h2>
              <h3 className='font-semibold text-2xl mt-4'>Questions and answers</h3>
              <p className='mt-8 text-gray-300'>Where is the nearest dealer to me? What are Jovico eBike experts? You will find the answers to these and other exciting questions about the Jovico eBike system here.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='max-w-[90rem] mx-auto py-24'>
            <div className='p-4 lg:p-8 max-w-3xl'>
              <h2 className='text-2xl md:text-3xl underline text-white'>Our telephone support</h2>
              <p className='my-4 text-gray-300'>In addition to the contact form above, Jovico eBike systems’ customer support can be reached at the toll-free phone number below. Standard usage charges from your telephone service provider may apply.<br /><br />Hours of operation are Monday-Friday, 8am-8pm West African Time. Closed on holidays.</p>
            </div>
            <div className='max-w-xl p-4 bg-gray-900/30 mx-8'>
              <h2 className='text-2xl md:text-3xl text-white'>Nigeria</h2>
              <p className='mt-4 text-gray-300'>Tel: +234-810-066-5479</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div >
            <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.4142430196957!2d3.2406207758921997!3d6.469094423791323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8613d5c216b9%3A0xd60bb02e3dfb36de!2sAspamda%20Plaza%2C%20Trade%20Fair%20Complex%20102102%2C%20Lagos!5e0!3m2!1sen!2sng!4v1696535541585!5m2!1sen!2sng' className='w-full h-[40rem] border-0' title='jovico location' allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade'></iframe>
          </div>
        </div>
      </section>
    </main>
  )
  return content
}

export default Contact
