const Help = () => {
  const content = (
    <main className='bg-black'>
      <section>
        <div className='mx-auto max-w-[90rem] py-24 sm:px-6 sm:py-32 lg:px-8'>
          <div className='relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0'>
            <div className='mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left'>
              <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
                How can we help you?
              </h2>
              <p className='mt-6 text-lg leading-8 text-gray-300'>
              We are here for you. Feel free to express your thoughts and concerns using the form below. We are committed to taking active steps within 72hours where needed.
              </p>
            </div>
            <div className='relative mt-16 h-80 lg:mt-8'>
              <img
                className='absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10 object-bottom'
                src='images/help.jpeg'
                alt='App screenshot'
                width={1824}
                height={1080}
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='grid sm:grid-cols-2'>
            <div><img src='images/contact.jpeg' className='' alt='need any help?' /></div>
            <div className='p-4 lg:p-8 text-white'>
              <div>
                <h2 className='text-2xl'>Any further enquiries?</h2>
                <h3 className='font-semibold text-2xl mt-4'>We are here to help</h3>
              </div>
              <div className='mt-8'>
                <a
                  href='/contact'
                  className='px-12 py-3 rounded-md border-2 border-white hover:bg-white/20 duration-300'
                >Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='max-w-[90rem] mx-auto py-24'>
            <div className='p-4 lg:p-8 max-w-3xl'>
              <h2 className='text-2xl md:text-3xl underline text-white'>Our telephone support</h2>
              <p className='mt-4 text-gray-300'>In addition to the help resources above, Jovico eBike systems customer support can be reached at the toll-free phone number below. Standard usage charges from your telephone service provider may apply.<br /><br />Hours of operation are Monday-Friday, 8am-8pm West African Time. Closed on holidays.</p>
            </div>
            <div className='bg-gray-900/30 lg:mx-8 mt-8 p-4 lg:p-8 max-w-xl'>
              <h2 className='text-2xl md:text-3xl text-white'>Nigeria</h2>
              <p className='mt-4 text-gray-300'>Tel: +234-123-456-7890</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
  return content
}

export default Help
