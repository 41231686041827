import React, { useState } from 'react'
import { Dialog, Disclosure } from '@headlessui/react'
import { List, PhoneVibrateFill, QuestionCircleFill, X } from 'react-bootstrap-icons'

const links = [
  { title: 'eBikes', href: '/inventory/bikes' },
  { title: 'Spares', href: '/inventory/spares' },
  { title: 'Rentals', href: '/services/rentals' },
  { title: 'Repairs', href: '/services/repairs' },
  { title: 'About', href: '/about' },
]
const aux = [
  { title:'Contact', icon: <PhoneVibrateFill size={18} />, href: '/contact' },
  { title:'Help', icon: <QuestionCircleFill size={18} />, href: '/help' },
]

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const content = (
    <header className='bg-black fixed inset-x-0 top-0 z-50'>
      <nav className='p-4 flex justify-between font-bold items-center text-white'>
        <div>
          <a href='/' className='w-32 cursor-pointer'>
            <span className='sr-only'>Jovico World</span>
            <div className='flex items-center justify-center'>
              <img width={130} src='/images/logos/logo-white.svg' alt='Logo' />
            </div>
          </a>
        </div>

        <div className='mr-4 flex lg:hidden'>
          <button
            type='button'
            className='-m-2 border-2 inline-flex items-center justify-center rounded-md p-2 text-white'
            onClick={() => setMobileMenuOpen(true)}
          >
	          <span className='sr-only'>Open main menu</span>
	          <List size={20} aria-hidden='true' />
          </button>
        </div>

        <div className='hidden lg:inline'>
          <div className='flex space-x-2 text-sm'>
            { links.map((link, idx) => (
              <div
                key={idx}
                className='py-1 px-3 hover:bg-slate-800 hover:rounded-full cursor-pointer'
              >
                <a href={link.href}>{link.title}</a>
              </div>
            ))}
          </div>
        </div>

        <div className='hidden lg:inline'>
          <div className='flex space-x-4 mr-2 text-sm'>
            { aux.map((link, idx) => (
              <div
                key={idx}
                className='p-2 hover:bg-slate-800 hover:rounded-full cursor-pointer'
              >
                <a href={link.href}>{link.icon}</a>
              </div>
            ))}
          </div>
        </div>
      </nav>

      <Dialog as='div' className='lg:hidden' open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className='fixed inset-0 z-50' />
        <Dialog.Panel className='fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black py-4 px-5 sm:max-w-[28rem] sm:ring-1 sm:ring-black/10'>
          <div className='flex items-center justify-between'>
            <a href='/' className='-ml-1'>
              <span className='sr-only'>Jovico World</span>
              <div className='flex items-center justify-center'>
                <img width={130} src='/images/logos/logo-white.svg' alt='Logo'/>
              </div>
            </a>
            <button
              type='button'
              className='-m-2 mr-1.5 inline-flex border-2 rounded-full text-gray-300 font-bold p-2 text-white'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <X size={20} aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                { [...links, ...aux].map((item,idx) => (
                  <Disclosure as='div' className='hover:pl-4' key={idx}>
                    <Disclosure.Button
                      as='a'
                      href={item.href}
                      className='flex items-center justify-between rounded-lg -ml-1 py-2 px-4 text-base w-full font-semibold leading-7 hover:bg-gray-800 active:bg-gray-800 text-white'
                    >
                      {item.title}
                    </Disclosure.Button>
                  </Disclosure>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
  return content
}

export default Navbar
