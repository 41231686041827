import { Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import ConvertCurrency from '../convertCurrency'
import BuyButton from './BuyButton'

const ShowSpare = ({ spare, close, open }) => {
  const content = (
    <Transition appear show={open} as={Fragment}>
      <Dialog as='div' className='relative z-60' onClose={close}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-90' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex mt-20 min-h-full items-center justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-6xl transform overflow-hidden rounded-2xl align-middle shadow-xl transition-all m-4 px-6 md:px-12 bg-black border border-gray-900'>
                <div className=''>
                  <div>
                    <div className='p-10 mx-auto w-full md:px-24 lg:px-8 lg:py-20'>
                      <div className='flex w-full gap-x-8'>
                        <p className='mb-6 text-base text-gray-100 md:text-lg'>
                          <ConvertCurrency dollar={spare?.price}/>
                        </p>
                        <p>Equivalent to ${spare?.price}</p>
                        <Dialog.Title
                          as='h1'
                          className='font-bold ml-auto uppercase leading-6 text-3xl text-white'
                        >
                          {spare.name}
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className='px-6 md:px-8'>
                        {/* <span className='mx-7'>·</span> */}
                        <p className=''>Category: {spare.cat}</p>
                    </div>
                  </div>

                  <div className='w-full mx-auto'>
                    <div className='flex justify-center'>
                      <img src={spare.image} alt={spare.name} />
                    </div>
                  </div>

                  <div className='max-w-4xl mx-auto text-center'>
                    <Dialog.Description>
                      <h2 className='text-xl font-medium mt-10 mb-8'>About</h2>
                      <p className='mb-2 text-gray-300'>{spare.desc}</p>
                    </Dialog.Description>

                    <div className='inline-flex justify-center mt-24 md:mt-36'>
                      {/* <a
                        href='https://wa.me/message/X3HDZKMUUHZEL1'
                        className='inline-flex justify-center rounded-md px-12 py-3 text-sm font-medium focus:outline-none text-black hover:bg-gray-100 bg-gray-300'
                      >
                        Get a Quote
                      </a> */}
                      <BuyButton id={spare.buyButtonId} />
                      <button
                        onClick={close} 
                        className='rounded-md md:ml-3 px-16 h-11 text-sm font-medium focus:outline-none text-black hover:bg-gray-100 bg-gray-300' 
                        type='button'>
                        Continue shopping
                      </button>
                    </div>
                    <div className='max-w-3xl mx-auto my-9 p-6'>
                      <p className='text-xs text-gray-500'>
                        Thanks for checking out this spare part or accessory and your intending purchase of same. Do explore our other services to help you get the best out of the accesory. If you got any specific inquiries about our espares and services, do reach out to us on any of the <a className='text-gray-300 hover:text-white' href='/contact'>options we've provided</a></p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
  return content
}

export default ShowSpare