import React from 'react'
// import { FullPage, section } from 'react-full-page'
import { ArrowDown, Bicycle, Bullseye, Gear, Headset, Magic, UniversalAccess } from 'react-bootstrap-icons'

const Home = () => {
  const deliver = [
    { icon: <Bullseye size={36} />, desc: 'Industry-Leading Innovations' },
    { icon: <Headset size={36} />, desc: '24/7 customer support' },
    { icon: <Bicycle size={36} />, desc: 'Trusted E-Bike Distributor' },
    { icon: <Gear size={36} />, desc: 'Comprehensive E-Bike Services' },
    { icon: <Magic size={36} />, desc: 'Top-Quality Spare Parts' },
    { icon: <UniversalAccess size={36} />, desc: 'Safety-First Accessories' }
  ]
  const works = [
    { number: '1', title: 'Visit our store', desc: 'Visit our store and chat with our experts and figure out the best plan for your needs.' },
    { number: '2', title: 'Get your e-bike', desc: 'Leave the store with your new e-bike!' },
    { number: '3', title: 'Stay connected', desc: 'In addition to periodic checkins, schedule repairs on this webpage and join our riders support group.' }
  ]

  const content = (
    <main className='bg-black'>
      <section>
        <div className='relative flex justify-center h-screen mb-12 overflow-hidden'>
          <video autoPlay muted loop className='absolute z-10 w-auto min-w-full min-h-full max-w-none'>
            <source src='videos/intro.mp4' type='video/mp4' />
            Your browser does not support the video tag.
          </video>

          <div className='flex flex-col w-full max-w-4xl mx-auto p-6 z-30'>
            <div className='text-center ltr:sm:text-left rtl:sm:text-right mt-20 mb-auto'>
              <h1 className='font-bold text-4xl text-white'>Jovico J1</h1>
            </div>
            <div className='grid gap-3 sm:inline-flex sm:justify-center mt-auto w-full'>
              <a className='py-3 px-12 inline-flex justify-center items-center gap-x-2 text-center bg-white/75 hover:bg-white/90 text-black text-sm font-medium rounded-md focus:outline-none focus:ring-none transition sm:w-auto' href='/discover/schedule'>
                Demo Drive
              </a>
              <a className='py-3 px-12 inline-flex justify-center items-center rounded-md font-medium bg-black/75 hover:bg-black/90 text-white focus:outline-none focus:ring-none transition-all text-sm sm:w-auto' href='/inventory/bikes'>
                Order Now
              </a>
            </div>
            <div className='text-center ltr:sm:text-left rtl:sm:text-right pt-4'>
              <p className='text-sm text-white'>Experience the e-bike revolution with Jovicobikes. Explore, ride, and innovate with us today!</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='flex items-center justify-center px-4 lg:px-8 py-10 min-h-screen max-w-[90rem] mx-auto'>
            <div className='grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-12 lg:items-center text-gray-300'>
              <div>
                <h1 className='block font-semibold text-3xl  md:text-5xl text-white'>
                  Why choose a Jovico eBike?
                </h1>
                <div className='py-10 mt-3 grid grid-cols-3 gap-6'>
	                { deliver.map((item, idx) => (
                    <div key={idx}>
                      <div>{item.icon}</div>
                      <p className='mt-4'>{item.desc}</p>
                    </div>
                  ))}
                </div>
                <div className='mt-6'>
                  <p>Our mission is to provide high-quality electric bikes that enhance the mobility, comfort and well-being of our customers, while also reducing the environmental and economic impact of traditional transportation.</p>
                </div>
              </div>

              <div className='mt-10 lg:mt-0'>
                <img className='w-full rounded-xl object-cover object-bottom h-[32rem]' src='images/image-47.jpeg' alt='eBike intro' />
              </div>
            </div>
	        </div>
        </div>
      </section>

      <section>
        <div>
          <div className='bg-[url(media/images/image-59.jpg)] bg-cover bg-center bg-no-repeat'>
            <div className='bg-black/75'>
              <div className='flex flex-col w-full max-w-[90rem] p-6 lg:items-center min-h-screen mx-auto'>
                <div className='max-w-4xl text-center'>
                  <h1 className='text-3xl font-extrabold sm:text-5xl text-white'>
                    Find your <span className='font-extrabold text-gray-300'>Forever eBike.</span>
                  </h1>
                  <p className='mt-4 sm:text-xl/relaxed text-white mb-auto'>
                    Our electric bikes are designed to be durable, reliable, safe and easy to use.
                  </p>
                </div>
                
                <div className='grid gap-3 sm:inline-flex sm:justify-center mt-auto w-full'>
                  <a className='py-3 px-12 inline-flex justify-center items-center gap-x-2 text-center bg-white/75 hover:bg-white/90 text-black text-sm font-medium rounded-md focus:outline-none focus:ring-none transition sm:w-auto' href='/inventory/bikes'>
                    Get started
                  </a>
                  <a className='py-3 px-12 inline-flex justify-center items-center rounded-md font-medium bg-black/75 hover:bg-black/90 text-white focus:outline-none focus:ring-none transition-all text-sm sm:w-auto' href='/contact'>
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='flex items-center justify-center px-4 lg:px-8 py-10 min-h-screen max-w-[90rem] mx-auto'>
            <div className='grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-12 lg:items-center'>
              <div className='mt-10 lg:mt-0'>
                <img className='w-full h-[40rem] object-cover rounded-xl' src='images/image-42.jpg' alt='eBike intro' />
              </div>

              <div className='mt-10 lg:mt-0'>
                <h1 className='block font-semibold text-3xl sm:text-4xl lg:text-6xl text-white'>Our Process</h1>
                <div className='mt-10 space-y-8'>
                  { works.map((item, idx) => (
                    <div key={idx} className='flex text-gray-300'>
                      <div className='w-10 flex items-center'><h3 className='text-3xl'>{item.number}</h3></div>
                      <div className='max-w-xl w-full'>
                        <h4 className='text-2xl'>{item.title}</h4>
                        <p className='mt-2 text-md'>{item.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
	        </div>
        </div>
      </section>

      <section>
        <div>
          <div className='relative flex overflow-hidden min-h-screen'>
            <div className='bg-black/75 sm:from-black/95 sm:to-black/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l'></div>
            <video autoPlay muted loop className='absolute w-auto min-w-full min-h-full max-w-none z-10'>
              <source src='videos/intro-5.mp4' type='video/mp4' />
              Your browser does not support the video tag.
            </video>

            <div className='flex flex-col w-full p-6 items-center max-h-auto max-w-[90rem] mx-auto z-30'>
              <div className='max-w-4xl text-center'>
                <h1 className='text-3xl font-extrabold sm:text-5xl text-white'>
                  Experience Jovico eBikes
                </h1>
                <p className='mt-4 sm:text-xl/relaxed text-white mb-auto'>
                  Schedule a demo drive today
                </p>
              </div>
              <div className='grid gap-3 sm:inline-flex sm:justify-center text-center mt-auto w-full'>
                <a
                  href='/discover/schedule'
                  className='block w-full rounded px-12 py-3 text-sm sm:w-auto duration-300 font-medium text-white focus:outline-none ring-2 ring-white hover:bg-white hover:text-black hover:ring-gray-100'
                >
                  Schedule a Demo Drive
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className='flex items-center justify-center px-4 lg:px-8 py-10 min-h-screen max-w-[90rem] mx-auto'>
            <div className='space-y-4 w-full'>
              <details className='group [&_summary::-webkit-details-marker]:hidden' open>
                <summary className='flex cursor-pointer items-center justify-between border-b border-white/5 p-4 text-gray-100'>
                  <h2 className='font-medium text-5xl'>
                    Locations
                  </h2>

                  <ArrowDown size={36} className='ml-auto transition duration-300 group-open:-rotate-180'/>
                </summary>

                <div className='mt-4 px-4 leading-relaxed text-white'>
                  <div>
                    <div className='flex gap-x-6'>
                      <div className='w-1/2'>
                        <h3 className='text-3xl'>Nigeria</h3>
                        <div className='mt-10'>
                          <h6 className='text-xl mb-4'>Opening times:</h6>
                          <p>Monday - Friday: 10:00AM to 7:00PM</p>
                          <p>Saturday: 10:00AM to 6:00PM</p>
                          <p>Sunday: Closed</p>
                        </div>
                      </div>
                      <div className='w-1/2'>
                        <h3 className='text-3xl'>Lagos</h3>
                        <h4 className='text-xl mt-10 mb-4'>TradeFair</h4>
                        <div className='text-white/50'>
                          <div>
                            <p>Block 10, Office and Warehouse Plaza, ASPAMDA, TradeFair Complex, Badagry ExpressWay, Ojo</p>
                            <p className='mt-1'>+234 (810) 066 5479</p>
                            <p className='mt-1'>info@jovicoworld.com</p>
                            <p className='mt-1'><a href='/contact'>Get directions</a></p>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </details>
            </div>
	        </div>
        </div>
      </section>

      <section>
        <div>
          <div className='bg-[url(media/images/image-57.jpg)] bg-cover bg-center bg-no-repeat'>
            <div className='bg-black/75'>
              <div className='flex flex-col w-full max-w-[90rem] p-6 lg:items-center min-h-screen mx-auto'>
                <div className='max-w-4xl text-center ltr:sm:text-left rtl:sm:text-right'>
                  <h1 className='text-3xl font-extrabold sm:text-5xl text-white'>
                    Spare Parts & Accessories
                  </h1>
                </div>
                <div className='sm:justify-center text-center mt-auto'>
                  <a
                    href='/inventory/spares'
                    className='block w-full rounded px-12 py-3 text-sm sm:w-auto duration-300 font-medium text-black focus:outline-none ring-2 ring-white bg-white hover:bg-transparent hover:text-white hover:ring-gray-100'
                  >
                    Shop Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
  return content
}

export default Home
